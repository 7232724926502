import * as React from "react";
import { Component } from 'react';
import  { Container, Row, Col} from 'react-bootstrap';

class Content extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    }
  }
  
  render() {
    return (
      <div className={`${this.props.show ? 'd-block' : 'd-none'}`}>
        <Container fluid className="not-found ">
          <Container className="px-0">
            <Row>
              <Col xs={12} lg={10} className="mx-auto">
                <div className="content">
                  <div className="title">
                    404
                    <span className="small">
                      ERROR
                    </span>
                  </div>
                  <p>
                    <span className="font-heavy">We're sorry.<br className="d-block d-md-none"/></span> 
                    &nbsp;
                    <span className="font-medium">
                      The page you <br className="d-none d-md-block" />requested is not available.
                    </span>
                  </p>
                  <a href="/">
                    Go back to the home page <i className="fa fa-chevron-right"></i>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    )
  }
}

export default Content;